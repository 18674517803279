import React, { useEffect, useState } from "react";
import { Link } from "gatsby"
import { Layout, FiltersNav } from '../components';
import { Helmet } from "react-helmet";
import { ResponsiveImage } from "../components"
import { getArtworks, formatCCFixed, getArtists, getDrops } from "../components/shared/Utils";
import { Select2 } from "select2-react-component";


// markup
const Artworks = ({ location }) => {


	const [artworksLoader, setArtworksLoader] = useState(null);
	const [artworksList, setArtworksList] = useState([]);

	const [artworksOrderBy, setArtworksOrderBy] = useState('d');
	const [artworksOrderDir, setArtworksOrderDir] = useState('desc');

	const [artistsList, setArtistsList] = useState(null);
	const [dropsList, setDropsList] = useState(null);

	const [artworksFilterDrop, setArtworksFilterDrop] = useState(null);
	const [artworksFilterArtist, setArtworksFilterArtist] = useState(null);

	const sortList = [
		{ value: 'd_desc', label: 'Most recent first' },
		{ value: 'd_asc', label: 'Oldest first' },
		{ value: 'n_asc', label: 'Name Ascending' },
		{ value: 'n_desc', label: 'Name Descending' },
	];



	useEffect(() => {
		getArtworks(setArtworksLoader, { offset: 0, limit: 12, order_by: artworksOrderBy, order_direction: artworksOrderDir });
		getArtists(setArtistsList, { limit: -1, order_by: 'n', order_direction: 'asc' });
		getDrops(setDropsList, { limit: -1, order_by: 'dl', order_direction: 'asc' });

		return () => {
			document.querySelector("html").classList.remove("no-scroll");
			document.querySelector("body").classList.remove("no-scroll");
		}

	}, []);

	useEffect(() => {
		if (artworksLoader == null) {
			return;
		}

		setArtworksList(artworksList.concat(artworksLoader.i));
	}, [artworksLoader]);

	const onLoadMoreArtworks = () => {
		getArtworks(setArtworksLoader, { offset: artworksList.length, limit: 12, order_by: artworksOrderBy, order_direction: artworksOrderDir });
	}

	const onToggleFilters = (evt) => {
		console.log(evt.target.checked);

		if (evt.target.checked) {
			document.querySelector("html").classList.add("no-scroll")
			document.querySelector("body").classList.add("no-scroll")
		} else {
			document.querySelector("html").classList.remove("no-scroll")
			document.querySelector("body").classList.remove("no-scroll")
		}
	}

	const onChangeFilterDrop = (value) => {
		console.log('Drop selected: ' + value);
		let real_value = value == '-' ? null : value;
		setArtworksFilterDrop(real_value);
		setArtistsList(null);
		getArtists(setArtistsList, { limit: -1, order_by: 'n', order_direction: 'asc', filters: { drop: real_value } });
	};

	const onChangeFilterArtist = (value) => {
		console.log('Artist selected: ' + value);
		let real_value = value == '-' ? null : value;
		setArtworksFilterArtist(real_value);
		setDropsList(null);
		getDrops(setDropsList, { limit: -1, order_by: 'dl', order_direction: 'asc', filters: { artist: real_value } });
	};

	const onChangeFiltersSort = (value) => {
		let value_parts = value.split('_')
		setArtworksOrderBy(value_parts[0]);
		setArtworksOrderDir(value_parts[1]);
	}

	const onSubmitFilters = () => {
		setArtworksLoader(null);
		setArtworksList([]);
		getArtworks(setArtworksLoader, {
			offset: 0, limit: 12, order_by: artworksOrderBy, order_direction: artworksOrderDir, filters: {
				drop: artworksFilterDrop,
				artist: artworksFilterArtist,
			}
		});
	};

	const onResetFilter = () => {
		// clear selected filters
		setArtworksFilterDrop(null);
		setArtworksFilterArtist(null);
		setArtworksOrderBy('d');
		setArtworksOrderDir('desc');

		// reset dropdowns and lock them
		setArtistsList(null);
		setDropsList(null);

		// get fresh lists
		getArtists(setArtistsList, { limit: -1, order_by: 'n', order_direction: 'asc' });
		getDrops(setDropsList, { limit: -1, order_by: 'dl', order_direction: 'asc' });
	}

	return (

		<Layout location={location}>

			<Helmet>
				<link href="/assets/css/artworks.css" rel="stylesheet" type="text/css" />
				<title>Ephemeral/Ethernal - Artworks</title>
			</Helmet>


			<section data-name="layout" id={'artworks_page'}>
				<div className={'page-container'}>

					<FiltersNav location={location} items={artworksLoader == null ? '...' : artworksLoader.t} />

					<div className={'artworks-bg p-0'}></div>

					<div className={'artworks-container container-fluid d-flex align-items-center flex-column'}>

						<div className={'page-title col-12 col-md-11'}>
							<h1 className="artworks-title pb-4">NFT<br />Collec<br />tions</h1>
						</div>

						<div className={'col-11 cards-container'}>

							{artworksLoader == null ? (

								<>
									{/* <div className={'container-fluid text-center my-5'}>
										Loading...
									</div> */}

									<div className={'content-placeholder my-4 col-12 col-md-6 col-xl-4 col-xxl-3'}>
										<div className={'card w-100'}>
											<div className={'dummy-img gradient'}></div>
											<div className={'dummy-title gradient'}>&nbsp;</div>
											<div className={'dummy-description gradient'}>&nbsp;</div>
											<div className={'dummy-artist'}>
												<div className={'circle gradient'}>&nbsp;</div>
												<div className={'artist-name gradient'}>&nbsp;</div>
											</div>
										</div>
									</div>

									<div className={'content-placeholder my-4 col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-md-block'}>
										<div className={'card w-100'}>
											<div className={'dummy-img gradient'}>&nbsp;</div>
											<div className={'dummy-title gradient'}>&nbsp;</div>
											<div className={'dummy-description gradient'}>&nbsp;</div>
											<div className={'dummy-artist'}>
												<div className={'circle gradient'}>&nbsp;</div>
												<div className={'artist-name gradient'}>&nbsp;</div>
											</div>
										</div>
									</div>

									<div className={'content-placeholder my-4 col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-xl-block'}>
										<div className={'card w-100'}>
											<div className={'dummy-img gradient'}>&nbsp;</div>
											<div className={'dummy-title gradient'}>&nbsp;</div>
											<div className={'dummy-description gradient'}>&nbsp;</div>
											<div className={'dummy-artist'}>
												<div className={'circle gradient'}>&nbsp;</div>
												<div className={'artist-name gradient'}>&nbsp;</div>
											</div>
										</div>
									</div>

									<div className={'content-placeholder my-4 col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-xxl-block'}>
										<div className={'card w-100'}>
											<div className={'dummy-img gradient'}>&nbsp;</div>
											<div className={'dummy-title gradient'}>&nbsp;</div>
											<div className={'dummy-description gradient'}>&nbsp;</div>
											<div className={'dummy-artist'}>
												<div className={'circle gradient'}>&nbsp;</div>
												<div className={'artist-name gradient'}>&nbsp;</div>
											</div>
										</div>
									</div>
								</>

							) : (
								<>
									{artworksLoader.i ? (

										artworksList.map((artworks) => {
											return (
												<>
													{/* CARD: start */}
													<div className={'card my-4 col-12 col-md-6 col-xl-4 col-xxl-3'}>
														<div className={'artwork-img'}>
															<a href={artworks.permalink} rel="noopener" target="_blank" >
																<div className={'open-hover-fx'}>Open</div>
																<ResponsiveImage
																	initialSrc={artworks.image_url}
																	alt={"Artwork Image"}
																/>
															</a>
														</div>

														<div className={'artwork-title'}>{artworks.short_name}</div>
														<div className={'artwork-description'}>{artworks.description}</div>

														<Link to={'/artists/detail/?' + artworks.artist.slug} className={'artwork-artist'}>
															<div className={'avatar'}>
																{artworks.artist.avatar_paths ? (
																	<ResponsiveImage
																		initialSrc={artworks.artist.avatar_paths['24']}
																		initialSrcSet={`${artworks.artist.avatar_paths['24']} 1x, ${artworks.artist.avatar_paths['48']} 2x`}
																		alt="Artist photo"
																		sources={[
																			{
																				srcset: [
																					{
																						src: artworks.artist.avatar_paths['24_webp'],
																						xd: "1x",
																					},
																					{
																						src: artworks.artist.avatar_paths['48_webp'],
																						xd: "2x",
																					}
																				],
																			},
																			{
																				srcset: [
																					{
																						src: artworks.artist.avatar_paths['24'],
																						xd: "1x",
																					},
																					{
																						src: artworks.artist.avatar_paths['48'],
																						xd: "2x",
																					}
																				],
																			}
																		]}
																	/>
																) : (
																	artworks.artist.name.substr(0, 1)
																)}
															</div>
															<div className={'artist-name'}>{artworks.artist.name}</div>
														</Link>

														<div className={'artwork-auction'}>
															{artworks.last_price ? (<div>last price</div>) : null}
															<div className={'bid'}>
																<div>
																	{artworks.last_price ? (
																		<>
																			<div className={'fixed-with-gradient'}>{formatCCFixed(artworks.last_price, artworks.last_payment_token.decimals, 4).replace('.', ',')}
																			</div>
																			<div>{artworks.last_payment_token.symbol}</div>
																		</>
																	) : (
																		<a href={artworks.permalink} rel="noopener" target="_blank" className={'not-on-sale'}>make an offer</a>
																	)}
																</div>
																<a href={artworks.permalink} rel="noopener" target="_blank" className={'btn btn--secondary'}>OpenSea</a>
															</div>
														</div>
													</div>
													{/* CARD: end */}

												</>
											);

										})
									) : (
										<div className={'container-fluid text-center my-5'}>Unable to load assets from OpenSea</div>
									)}
								</>
							)}


						</div>



						{/* Load More: start */}
						<div className={'load-more my-4 col-12 col-md-11 d-flex justify-content-center' + ((artworksLoader == null) || (artworksLoader != null && artworksList.length === artworksLoader.t) ? ' d-none' : '')}>
							<button className={'btn btn--secondary'} onClick={onLoadMoreArtworks}>
								Load More
								<div className={'btn-arrow'}></div>
							</button>
							<div className="load-more-count">
								{artworksLoader == null ? '...' : artworksLoader.t - artworksList.length}
							</div>
						</div>
						{/* Load More: end */}

					</div>
				</div>
			</section>

			<input type="checkbox" id={'toggle_filters_modal'} onChange={onToggleFilters} autoComplete="off" hidden />
			<section id={'filters_artworks_page'} className={'filters-artworks-page'}>
				<div className={'container-fluid'}>

					<div className={'title-container d-flex justify-content-center'}>
						<div className={'title col-10 my-3 my-lg-5'}>Filter by</div>
					</div>

					<div className={'d-flex justify-content-center'}>

						<div className={'filters-dropdown col-10 d-flex justify-content-between flex-column flex-md-row flex-wrap'}>
							<div className={'my-2'}>
								<div className={'my-2'}>
									Drop
								</div>

								<Select2
									data={dropsList == null ? [{ value: '-', label: 'All drops' }] : [{ value: '-', label: 'All drops' }].concat(dropsList.i.map((drop) => {
										return {
											value: drop.slug,
											label: 'Drop #' + drop.number + ' - ' + drop.name,
										};
									}))}
									value={'-'}
									update={onChangeFilterDrop}
									disabled={dropsList == null ? true : false}
								/>
							</div>

							<div className={'my-2'}>
								<div className={'my-2'}>
									Artist
								</div>

								<Select2 data={artistsList == null ? [{ value: '-', label: 'All artists' }] : [{ value: '-', label: 'All artists' }].concat(artistsList.i.map((artist) => {
									return {
										value: artist.slug,
										label: artist.name,
										component: (inp) => <span>{artist.avatar_paths ? <img src={artist.avatar_paths[24]} /> : ''} {artist.name}</span>
									}
								}))}
									value={'-'}
									update={onChangeFilterArtist}
									disabled={artistsList == null ? true : false}
									placeholder={artistsList == null ? 'Loading artists...' : 'Choose an artist'} />
							</div>


							<div className={'my-2'}>
								<div className={'my-2'}>
									Sort
								</div>

								<Select2 data={sortList}
									value={'d_desc'}
									update={onChangeFiltersSort}
								/>

							</div>
						</div>

					</div>

					<div className={'filter-btn-container d-flex justify-content-center'}>
						<div className={'col-10 d-flex justify-content-between justify-content-lg-end'}>

							{/* <div className={'cancel-btn'}>
								<button className={'btn btn--secondary'} onClick={onResetFilter}>
									Reset
								</button>
							</div> */}

							<div className={'cancel-btn'}>
								<label htmlFor={'toggle_filters_modal'} className={'btn btn--secondary'}>
									Cancel
								</label>
							</div>
							<div className={'submit-btn'}>
								<label className={'btn btn--secondary'} onClick={onSubmitFilters} htmlFor={'toggle_filters_modal'}>
									<div className={'me-2'}>
										<img src={'/assets/img/icons/filters_icon_fff.svg'} alt={'alt-icon'} />
									</div>
									Filter
								</label>
							</div>
						</div>


					</div>

				</div>
			</section>

		</Layout >
	)
}

export default Artworks
